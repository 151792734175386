import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { paths } from "../../router";
import { useQueryParams, useSendViewSignCode, useSignView } from "../../../service";
import { useNavigate } from "react-router";
import { useLayoutEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { ConfirmationResponse, Token } from "../../../types";

export const ConfirmPersonView = () => {

  const {queryParams} = useQueryParams<{
    viewId: string,
    applicationId: string,
    token: string
  }>([
    {name: "viewId", type: "string"},
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"},
  ])

  const [isError, setIsError] = useState(false)

  const [code, setCode] = useState("")

  const [token, setToken] = useState("")

  const navigate = useNavigate()

  const closeConfirm = () => {
    navigate(`${paths.buyAppPath}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
  }

  const onChange = (val: string) => {
    if (isError) {
      setIsError(false)
    }
    setCode(val)
  }

  const onSuccessSendCode = (data: AxiosResponse<Token>) => {
    setToken(data.data.token)
  }

  const onSuccessSign = (data: AxiosResponse<ConfirmationResponse>) => {
    setIsError(!data.data.success)

    if (data.data.success) {
      closeConfirm()
    }
  }

  const {sign} = useSignView({onSuccess: onSuccessSign})

  const {send} = useSendViewSignCode({onSuccess: onSuccessSendCode})

  const onConfirm = () => {
    sign({
      viewId: queryParams.viewId,
      payload: {
        token,
        code
      }
    })
  }

  useLayoutEffect(() => {
    send({viewId: queryParams.viewId})
  }, [])

  const sendAgain = () => {
    send({viewId: queryParams.viewId})
  }

  return (
    <div className={"d-flex flex-column justify-content-between h-100 align-items-center"}>
      <div className={"h-50 d-flex flex-column justify-content-between"}>
        <div className={"subtitle w-100"}>
          Подтверждение личности
          <Close onClick={closeConfirm} className={"cursor-pointer"}/>
        </div>
        <div className="mt-5">
          <label htmlFor="confirm-person" className="form-label label">
            Введите код подтверждения
          </label>
          <input
            className={`form-control input-text ${isError && "input-text-error"}`}
            id="confirm-person"
            value={code}
            onChange={(e) => onChange(e.target.value)}
          />
          <div className={"text-white mt-2"}>
            В течение нескольких секунд на ваш
            телефон поступит звонок с уникального номера,
            отвечать на него не нужно.
            Введите последние 4 цифры входящего номера —
            это ваш код для входа в систему.
          </div>
          <div className={"text call-renew mt-2"} onClick={sendAgain}>
            Позвонить повторно
          </div>
          <div>
          </div>
        </div>
      </div>
      <button className={"btn btn-primary"} onClick={onConfirm}>
        Подтвердить
      </button>
    </div>
  )
}