type UseQueryParams = {
  name: string;
  type?: "boolean" | "string" | "number";
}[];

export const useQueryParams = <T extends Record<string, unknown>>(
  urlParams: UseQueryParams
): { queryParams: T } => {
  const params: any = {};
  const location = window.location
  const rawQueryParams = location.search;

  for (let i = 0; i < urlParams.length; i += 1) {
    let queryParam: any = new URLSearchParams(rawQueryParams).get(urlParams[ i ].name);

    if (queryParam) {
      params[ urlParams[ i ].name ] = queryParam;
    }
  }

  return {
    queryParams: params as T,
  };
};
