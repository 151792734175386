import { useLoadConcurrency, useQueryParams } from "../../../service";
import { useNavigate } from "react-router";
import { paths } from "../../router";
import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { ObjectPreview } from "../object-preview";
import { memo } from "react";

export const Competition = memo(() => {

  const {queryParams} = useQueryParams<{ applicationId: string, token: string }>([
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"}
  ])

  const navigate = useNavigate()

  const closeConfirm = () => {
    navigate(`${paths.sellAppPath}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
  }

  const openObject = (id: string) => {
    navigate(`${paths.analysisPath.view}?applicationId=${queryParams.applicationId}
    &objectId=${id}&token=${queryParams.token}`)
  }

  const {concurrency} = useLoadConcurrency({applicationId: queryParams.applicationId})

  return (
    <>
      {concurrency &&
        <div className={"d-flex flex-column"}>
          <div className={"subtitle"} style={{width: "346px"}}>
            Конкуренты
            <Close onClick={closeConfirm} className={"cursor-pointer"}/>
          </div>
          <div className={"d-flex flex-column gap-3 mt-40"}>
            {concurrency.data.map(el => (
              <ObjectPreview
                key={el.id}
                object={el}
                onClick={openObject}
              />
            ))}
          </div>
        </div>
      }
    </>
  )
})