import { AdCampaign } from "../../../types";
import { AdCard } from "../ad-card";
import { memo, useState } from "react";
import { ReactComponent as Arrow } from "../../../style/assets/icon-arrow-down.svg";

type Props = {
  adCampaign: AdCampaign[]
  otherAdCampaign?: AdCampaign[]
}

export const AdCampany = memo((
    {
      adCampaign,
      otherAdCampaign
    }: Props) => {

    const [open, setOpen] = useState(false)

    return (
      <>
        <div className={"d-flex flex-column gap-2"}>
          {adCampaign.map(el => (
            <AdCard ad={el} key={el.adUrl}/>
          ))}
        </div>
        <div className={"d-flex justify-content-end align-items-center gap-2 expand mt-2 mb-2"}
             onClick={() => setOpen(!open)}>
          {!open && <div>Развернуть</div>}
          {open && <div>Свернуть</div>}
          <Arrow style={{rotate: `${open ? "180deg" : "0deg"}`}}/>
        </div>
        {open &&
          <div className={"d-flex flex-column gap-2"}>
            {otherAdCampaign?.map(el => (
              <AdCard ad={el} key={el.adUrl}/>
            ))}
          </div>
        }
      </>
    )
  }
)