import React from 'react';
import './App.scss';
import { Router } from "./view";

function App() {
  return (
    <div className="App h-100">
      <Router/>
    </div>
  );
}

export default App;
