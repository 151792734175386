import { api } from "../../transport";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka-public/load-public-view/DEFAULT_QN"

type Props = {
  id: string
}

export const useLoadPublicView = (
  {
    id
  }: Props) => {

  const loadPublicView = () => api.view.getPublicView(id)

  const {data, isLoading, refetch, remove, error, isFetching, isFetched} = useQuery(
    DEFAULT_QN,
    loadPublicView,
    {
      keepPreviousData: true
    }
  );

  return {
    publicView: data?.data,
    isLoading,
    isFetching,
    isFetched,
    refetch,
    remove,
    error
  }
}