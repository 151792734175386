import { ObjectGalleryCard } from "../../../types";
import React, { memo } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"

type Props = {
  object: ObjectGalleryCard
}

export const CarouselComponent = memo(({object}: Props) => {

    return (
      <div>
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          emulateTouch={true}
        >
          {object.photos.map(el => (
            <div key={el}>
              <img src={el} className="w-100 object-fit-cover"
                   style={{width: "375px", height: "200px"}}
                   alt={""}
              />
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
)