const sellAppPath = "/saleApplication"

const buyAppPath = "/buyApplication"

const competitionPath = {
  list: "/competition",
  view: "/competition/object"
}

const compilationPath = {
  list: "/compilation",
  view: "/compilation/object"
}

const viewPath = "/view"

const confirmPhonePath = "/confirm-phone"

const confirmShowPath = "/confirm-show"

const confirmViewPath = "/confirm-view"

const confirmPersonShowPath = "/confirm-person-show"

const confirmPersonViewPath = "/confirm-person-view"

const confirmPersonAgreementPath = "/confirm-person-agreement"

const agreementPath = "/agreement"

export const paths = {
  sellAppPath,
  buyAppPath,
  analysisPath: competitionPath,
  compilationPath,
  confirmViewPath,
  viewPath,
  confirmPersonShowPath,
  confirmPhonePath,
  confirmShowPath,
  confirmPersonViewPath,
  agreementPath,
  confirmPersonAgreementPath
}