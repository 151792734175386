import { api } from "../../transport";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka-public/load-concurrency-object/DEFAULT_QN"

type Props = {
  applicationId: string
  objectId: string
}

export const useLoadConcurrencyObject = (
  {
    applicationId,
    objectId
  }: Props) => {

  const loadConcurrencyObject = () => api.sale.getConcurrencyObject(applicationId, objectId)

  const {data, isLoading, refetch, remove, error, isFetching, isFetched} = useQuery(
    DEFAULT_QN,
    loadConcurrencyObject,
    {
      keepPreviousData: true
    }
  );

  return {
    object: data?.data,
    isLoading,
    isFetching,
    isFetched,
    refetch,
    remove,
    error
  }
}