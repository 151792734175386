import axios from "axios";
import { config } from "./config";

const Client = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("sdelka-public-token")}`,
  },
});

Client.interceptors.response.use(
  (response) => {
    console.log(`status: ${response.status}`, `url: ${response.config.url}`)
    return response
  },
  (err) => {
    const {
      response: { },
    } = err;
    console.log(err)
    console.error(`${err} ${err.stack}`)
    return Promise.reject(err);
  }
);

export { Client };