import { formatThousand, useLoadPublicView, useQueryParams } from "../../../service";
import { CarouselComponent } from "../carousel";
import { Agent } from "../agent";
import { AdCampany } from "../ad-campany";
import { Compilation } from "../compilation";
import { ReactComponent as Logo } from "../../../style/assets/Logo.svg";
import { ViewCard } from "../view-card";
import { useEffect } from "react";

export const PublicView = () => {

  const {queryParams} = useQueryParams<{ id: string }>([
    {name: "id", type: "string"},
  ])

  const {publicView} = useLoadPublicView({id: queryParams.id})

  useEffect(() => {
    console.log(publicView)
  }, [publicView])

  return (
    <>
      {publicView &&
        <div>
          <CarouselComponent object={publicView.objectGalleryCard}/>
          <div className={"d-flex flex-column mt-3"}>
            <div className={"d-flex flex-row address-label justify-content-between"}>
              <div className={""}>
                {publicView.objectGalleryCard.title}
              </div>
              <div>
                {`${formatThousand(publicView.objectGalleryCard.price)} ₽`}
              </div>
            </div>
            <div className={"text mt-2"}>
              {publicView.objectGalleryCard.address}
            </div>
          </div>
          <Agent agent={publicView.agent}/>
          <div className={"mt-40 title mb-2"}>
            НАШ ОБЪЕКТ
          </div>
          <AdCampany
            adCampaign={publicView.adCampaign}
            otherAdCampaign={publicView.otherAdCampaign}
          />
          <Compilation
            type={"base"}
            id={queryParams.id}
          />
          <div className={"mt-40 title mb-2"}>
            ЗАПЛАНИРОВАННЫЙ ПРОСМОТР
          </div>
          <div
            className={"d-flex flex-row gap-2 mt-2 overflow-x-auto"}
            style={{width: "359px"}}
          >
            <ViewCard show={publicView.show}/>
          </div>
          <div className={"line mt-40"}/>
          <Logo className={"footerLogo"}/>
        </div>
      }
    </>
  )
}