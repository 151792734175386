import { api } from "../../transport";
import { useMutation } from "react-query";

const DEFAULT_MN = "sdelka-public/call-me/DEFAULT_MN";

export const useCallMe = () => {

  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({id, phone}: { id: string, phone: string }) => api.view.callMe(id, phone),
  });

  return {
    callMe: mutation.mutate,
    isLoading: mutation.isLoading
  }
}