import { api } from "../../transport";
import { useMutation } from "react-query";
import { Vote } from "../../types";

const DEFAULT_MN = "sdelka-public/vote/DEFAULT_MN";

type Props = {
  onSuccess: () => void
}

export const useVoteObject = (
  {
    onSuccess
  }: Props) => {

  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({applicationId, objectId, vote}: { applicationId: string, objectId: string, vote: Vote }) =>
      api.buy.voteToObject(applicationId, objectId, vote),
    onSuccess
  });

  return {
    vote: mutation.mutate,
    isLoading: mutation.isLoading
  }
}