import { useMutation } from "react-query";
import { ConfirmationRequest, ConfirmationResponse } from "../../types";
import { AxiosResponse } from "axios";
import { api } from "../../transport";

const DEFAULT_MN = "sdelka-public/sign-view/DEFAULT_MN";

type Props = {
  onSuccess?: (data: AxiosResponse<ConfirmationResponse>) => void
}

export const useSignView = (
  {
    onSuccess
  }: Props) => {

  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({viewId, payload}: { viewId: string, payload: ConfirmationRequest }) =>
      api.sign.signView(viewId, payload),
    onSuccess,
  });

  return {
    sign: mutation.mutate,
    isLoading: mutation.isLoading
  }
}