import { api } from "../../transport";
import { useMutation } from "react-query";
import { Token } from "../../types";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka-public/send-show-sign-code/DEFAULT_MN";

type Props = {
  onSuccess?: (data: AxiosResponse<Token>) => void
}

export const useSendShowSignCode = (
  {
    onSuccess
  }: Props) => {

  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({showId}: { showId: string }) =>
      api.sign.sendShowSignCode(showId),
    onSuccess,
  });

  return {
    send: mutation.mutate,
    isLoading: mutation.isLoading
  }
}