import { formatThousand, useLoadCollectionObject, useQueryParams, useVoteObject } from "../../../service";
import { useNavigate } from "react-router";
import { paths } from "../../router";
import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { CarouselComponent } from "../carousel";
import { ReactComponent as ArrowLeft } from "../../../style/assets/icon-left.svg";
import { ReactComponent as ArrowRight } from "../../../style/assets/icon-right.svg";
import { ReactComponent as Like } from "../../../style/assets/icon-like.svg";
import { ReactComponent as Dislike } from "../../../style/assets/icon-dislike.svg";
import { Vote } from "../../../types";
import { useEffect } from "react";

export const ObjectCardCollection = () => {

  const {queryParams} = useQueryParams<{
    applicationId: string,
    objectId: string,
    token: string,
    from: string
  }>([
    {name: "applicationId", type: "string"},
    {name: "objectId", type: "string"},
    {name: "token", type: "string"},
    {name: "from", type: "string"}
  ])

  const navigate = useNavigate()

  const closeConfirm = () => {
    if (queryParams.from === "application") {
      navigate(`${paths.buyAppPath}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
    } else {
      navigate(`${paths.compilationPath.list}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
    }

  }

  const openObject = (id?: string) => {
    if (id) {
      navigate(`${paths.compilationPath.view}?applicationId=${id}&objectId=${id}&token=${queryParams.token}`)
    }
  }

  const {object, refetch} = useLoadCollectionObject({
    applicationId: queryParams.applicationId,
    objectId: queryParams.objectId
  })

  const onSuccessVote = () => {
    refetch()
  }

  useEffect(() => {
    refetch()
  }, [queryParams.applicationId])

  const {vote: voteObject} = useVoteObject({onSuccess: onSuccessVote})

  const onClickVote = (vote: Vote) => {
    voteObject({
      applicationId: queryParams.applicationId,
      objectId: queryParams.objectId,
      vote
    })
  }

  return (
    <>
      {object &&
        <div className={"d-flex flex-column position-relative h-100"}>
          <div
            className={"d-flex align-items-center justify-content-end position-absolute z-2"}
            style={{width: "346px"}}
          >
            <Close onClick={closeConfirm} className={"cursor-pointer"}/>
          </div>
          <div className={"d-flex flex-column align-items-center"}>
            <div style={{marginLeft: "-1px !important"}} className={"ps-3"}>
              <CarouselComponent object={object.objectGalleryCard}/>
            </div>
            <div className={"d-flex flex-column g-2 mt-2 mb-4 p-2 w-100"}>
              <div className={"d-flex flex-row subtitle"}>
                <div>
                  {object.objectGalleryCard.title}
                </div>
                <div>
                  {`${formatThousand(object.objectGalleryCard.price)} ₽`}
                </div>
              </div>
              <div className={"text mt-2"}>
                {object.objectGalleryCard.address}
              </div>
            </div>
            <div className={"d-flex flex-column w-100 p-2"}>
              <div className={"label-white"}>
                Описание
              </div>
              <div className={"text mt-2"}>
                {object.description}
              </div>
            </div>
          </div>
          <div
            className={`d-flex flex-row position-absolute bottom-0 w-100 z-2 justify-content-between  align-items-center`}>
            <div
              className={"cursor-pointer arrow-wrapper"}
              onClick={() => openObject(object.prevId)}
            >
              {object.prevId && <ArrowLeft/>}
            </div>
            <div
              className={`${(object.voteStatus === "dislike") ? "vote-selected" : "vote-not-selected"}  arrow-wrapper`}
              onClick={() => onClickVote({voteStatus: "dislike"})}
            >
              <Dislike/>
            </div>
            <div
              className={`${(object.voteStatus === "like") ? "vote-selected" : "vote-not-selected"} 
              ${(object.voteStatus === "dislike") ? "vote-not-selected" : "vote-selected"}  arrow-wrapper`}
              onClick={() => onClickVote({voteStatus: "like"})}
            >
              <Like/>
            </div>
            <div className={"cursor-pointer arrow-wrapper"}
                 onClick={() => openObject(object.nextId)}
            >
              {object.nextId && <ArrowRight/>}
            </div>
          </div>
        </div>
      }
    </>
  )
}