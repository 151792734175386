import { Agreement } from "../../../types";
import { ReactComponent as Signed } from "../../../style/assets/signed.svg";
import { ReactComponent as NotSigned } from "../../../style/assets/not-signed.svg";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { paths } from "../../router";
import { memo } from "react";

type Props = {
  agreement: Agreement
  applicationId: string
  type: "sale" | "buy"
  token: string
}

export const AgreementComponent = memo((
  {
    agreement,
    applicationId,
    type,
    token
  }: Props) => {

  const navigate = useNavigate()

  const onOpen = () => {
    navigate(`${paths.agreementPath}?applicationId=${applicationId}&status=${agreement.status}
    &type=${type}&token=${token}&document=${agreement.documentUrl}`)
  }

  const getTitle = () => {
    switch (agreement.status) {
      case "offline":
        return `Договор оказания услуг заключен в письменной форме от ${dayjs(new Date(agreement.dateSigned || ""))
          .format("DD.MM.YYYY")}`
      case "oral":
        return `Договор оказания услуг заключен в устной форме от ${dayjs(new Date(agreement.dateSigned || ""))
          .format("DD.MM.YYYY")}`
      case "signed":
        return `Договор оказания услуг от ${dayjs(new Date(agreement.dateSigned || ""))
          .format("DD.MM.YYYY")}`
      case "wait":
        return `Договор оказания услуг от ${dayjs(new Date(agreement.dateSigned || ""))
          .format("DD.MM.YYYY")}`
      default:
        return ""
    }
  }

  return (
    <div className={"wrapper mt-40 d-flex flex-column"}>
      <div className={"subtitle"}>
        {getTitle()}
      </div>
      <div className={"d-flex align-items-center gap-1 mt-2"}>
        {agreement.status === "signed" && <Signed/>}
        {agreement.status === "wait" && <NotSigned/>}
        <div className={"label"}>
          {agreement.status === "signed" && "Подписано"}
          {agreement.status === "wait" && "Требует подписания"}
        </div>
      </div>
      <div className={"text mt-3"}>
        {`Действует до ${dayjs(new Date(agreement.validUntil || ""))
          .format("DD.MM.YYYY")} ${agreement.isProlonged ? "с пролонгацией" : "без пролонгации"}`}
      </div>
      {agreement.status !== "oral" &&
        <div className={"btn btn-primary mt-2 "}
             onClick={onOpen}>
          <div className={"text-center"}>
            Открыть
          </div>
        </div>
      }
    </div>
  )
})