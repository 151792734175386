import { api } from "../../transport";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka-public/load-concurrency/DEFAULT_QN"

type Props = {
  applicationId: string
}

export const useLoadConcurrency = (
  {
    applicationId
  }: Props) => {

  const loadConcurrency = () => api.sale.getConcurrency(applicationId)

  const {data, isLoading, refetch, remove, error, isFetching, isFetched} = useQuery(
    DEFAULT_QN,
    loadConcurrency,
    {
      keepPreviousData: true
    }
  );

  return {
    concurrency: data?.data,
    isLoading,
    isFetching,
    isFetched,
    refetch,
    remove,
    error
  }
}