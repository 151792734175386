import { createContext, useContext } from "react";
import { Client } from "./Client";

export const ClientContext = createContext<typeof Client | null>(null);

export function useClient(): typeof Client {
  const client = useContext(ClientContext);

  if (!client) {
    throw new Error("No context found");
  }

  return client;
}
