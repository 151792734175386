import { formatThousand, useLoadConcurrencyObject, useQueryParams } from "../../../service";
import { useNavigate } from "react-router";
import { paths } from "../../router";
import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { ReactComponent as ArrowLeft } from "../../../style/assets/icon-left.svg";
import { ReactComponent as ArrowRight } from "../../../style/assets/icon-right.svg";
import { ReactComponent as New } from "../../../style/assets/icon-link.svg";
import { CarouselComponent } from "../carousel";
import { useEffect } from "react";

export const ObjectCardCompetition = () => {

  const {queryParams} = useQueryParams<{
    applicationId: string,
    objectId: string,
    token: string
  }>([
    {name: "applicationId", type: "string"},
    {name: "objectId", type: "string"},
    {name: "token", type: "string"}
  ])

  const navigate = useNavigate()

  const closeConfirm = () => {
    navigate(`${paths.analysisPath.list}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
  }

  const openObject = (id?: string) => {
    if (id) {
      navigate(`${paths.analysisPath.view}?applicationId=${id}&objectId=${id}&token=${queryParams.token}`)
    }
  }

  const openAd = () => {
    window.open(`${object?.sourceUrl}`)
  }

  const {object, refetch} = useLoadConcurrencyObject(
    {
      applicationId: queryParams.applicationId,
      objectId: queryParams.objectId
    }
  )

  useEffect(() => {
    refetch()
  }, [queryParams.applicationId])

  return (
    <>
      {object &&
        <div className={"d-flex flex-column h-100 justify-content-between"}>
          <div
            className={"d-flex align-items-center justify-content-end position-absolute z-2"}
            style={{width: "346px"}}
          >
            <Close onClick={closeConfirm} className={"cursor-pointer"}/>
          </div>
          <div className={"d-flex flex-column w-100"}>
            <CarouselComponent object={object.objectGalleryCard}/>
            <div className={"d-flex flex-column g-2 mt-4 mb-4 "}>
              <div className={"d-flex flex-row subtitle"}>
                <div>
                  {object.objectGalleryCard.title}
                </div>
                <div>
                  {`${formatThousand(object.objectGalleryCard.price)} ₽`}
                </div>
              </div>
              <div className={"text mt-2"}>
                {object.objectGalleryCard.address}
              </div>
            </div>
            <div className={"w-100 d-flex justify-content-center"}>
              <div
                className={"btn btn-secondary d-flex align-items-center justify-content-center gap-2"}
                onClick={openAd}>
                {`Объявление на ${object.sourceName}`}
                <New/>
              </div>
            </div>
            <div className={"d-flex flex-column mt-40"}>
              <div className={"label-white mt-2"}>
                Преимущества
              </div>
              <div className={"text mt-2"}>
                {object.advantages}
              </div>
            </div>
            <div className={"d-flex flex-column"}>
              <div className={"label-white mt-2"}>
                Недостатки
              </div>
              <div className={"text mt-2"}>
                {object.disadvantages}
              </div>
            </div>
          </div>
          <div className={`d-flex flex-row w-100
           ${!object.nextId && "justify-content-end"}
           ${!object.prevId && "justify-content-start"}
           ${object.prevId && object.nextId && "justify-content-between"}
          align-items-center ps-2 pe-2`}>
            {object.prevId &&
              <div className={"cursor-pointer arrow-wrapper"} onClick={() => openObject(object.prevId)}>
                <ArrowLeft/>
              </div>
            }
            {object.nextId &&
              <div className={"cursor-pointer arrow-wrapper"} onClick={() => openObject(object.nextId)}>
                <ArrowRight/>
              </div>
            }
          </div>
        </div>
      }
    </>
  )
}