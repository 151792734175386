import { useLoadBuyApplication, useQueryParams } from "../../../service";
import { ReactComponent as Logo } from "../../../style/assets/Logo.svg";
import { ReactComponent as ArrowUp } from "../../../style/assets/icon-arrow-up-white.svg";
import { ReactComponent as ArrowDown } from "../../../style/assets/icon-arrow-down-white.svg";
import { useRef, useState } from "react";
import { Compilation } from "../compilation";
import { Agent } from "../agent";
import { AgreementComponent } from "../agreement";
import { Progress } from "../progress";
import { ViewCard } from "../view-card";


export const BuyApplication = () => {

  const {queryParams} = useQueryParams<{ applicationId: string, token: string }>([
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"}
  ])

  localStorage.setItem("sdelka-public-token", queryParams.token)

  const {application} = useLoadBuyApplication({applicationId: queryParams.applicationId})

  const [open, setOpen] = useState(false)

  return (
    <>
      {application &&
        <div>
          <div className={"map"}>
            {application.buyApplication.mapImage &&
              <img src={application.buyApplication.mapImage} alt={""}/>
            }
          </div>
          <div className={"d-flex flex-column mt-3"}>
            <div className={"d-flex flex-row address-label justify-content-between"}>
              <div className={""}>
                {application.buyApplication.title}
              </div>
              <div onClick={() => setOpen(!open)}>
                {open ? <ArrowUp/> : <ArrowDown/>}
              </div>
            </div>
            <div className={"text mt-2"}>
              {application.buyApplication.address}
            </div>
            {open &&
              <div className={"requirement "}
                   dangerouslySetInnerHTML={{__html: application.buyApplication.requirements}}
              />
            }
          </div>
          <Compilation
            type={"collection"}
            collection={application.collection}
            applicationId={queryParams.applicationId}
            token={queryParams.token}
          />
          <Agent agent={application.agent}/>
          {application.plannedShows &&
            <div className={"mt-40 title mb-2"}>
              ПРЕДСТОЯЩИЕ ПРОСМОТРЫ
            </div>
          }
          <div className={"d-flex flex-row gap-2 mt-2 overflow-x-auto overflow-no-scroll"}
               style={{width: "359px"}}
          >
            {application.plannedShows?.map(el => (
              <ViewCard show={el} key={el.id}/>
            ))}
          </div>
          {application.finishedShows && <div className={"mt-40 title mb-2"}>
            ПРОШЕДШИЕ ПРОСМОТРЫ
          </div>}
          <div className={"d-flex flex-row gap-2 mt-2 overflow-x-auto overflow-no-scroll"}
               style={{width: "359px"}}>
            {application.finishedShows?.map(el => (
              <ViewCard show={el} key={el.id}/>
            ))}
          </div>
          {application.agreement &&
            <AgreementComponent
              agreement={application.agreement}
              applicationId={application.id}
              type={"buy"}
              token={queryParams.token}
            />
          }
          <div className={"mt-40 title mb-2"}>
            ВЫПОЛНЕНИЕ ДОГОВОРА
          </div>
          <Progress progress={application.progress}/>
          <div className={"line mt-40"}/>
          <Logo className={"footerLogo"}/>
        </div>
      }
    </>
  )
}