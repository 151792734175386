const {
	NODE_ENV,
	REACT_APP_BACK: APP_BACK,
	REACT_APP_API_DEV_URL: API_DEV_URL,
	REACT_APP_API_STAGE_URL: API_STAGE_URL,
	REACT_APP_API_PROD_URL: API_PROD_URL,
} = process.env;

type ConfigType = {
	API_URL: string;
};

let currentBackend;

switch (APP_BACK) {
	case "prod": {
		currentBackend = API_PROD_URL;
		break;
	}
	case "stage": {
		currentBackend = API_STAGE_URL;
		break;
	}
	default: {
		currentBackend = API_DEV_URL;
	}
}

const defaultConfig: ConfigType = {
	API_URL: currentBackend as string,
};

const prodConfig: ConfigType = {
	...defaultConfig,
	API_URL: API_PROD_URL as string,
};

const isProduction = NODE_ENV === "production" && !process.env.REACT_APP_BUILD_DEV;

const config = isProduction ? prodConfig : defaultConfig;

export { config };
