import { BrowserRouter, Route, Routes } from "react-router-dom";
import { paths } from "./routes";
import {
  BuyApplication,
  Collection,
  Competition,
  ConfirmAgreement,
  ConfirmPersonAgreement,
  ConfirmPersonShow,
  ConfirmPersonView,
  ConfirmPhone,
  ConfirmShow,
  ConfirmView,
  ObjectCardCollection,
  ObjectCardCompetition,
  PublicView,
  SaleApplication
} from "../component";


export const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path={paths.buyAppPath} element={<BuyApplication/>}/>
        <Route path={paths.sellAppPath} element={<SaleApplication/>}/>
        <Route path={paths.analysisPath.list} element={<Competition/>}/>
        <Route path={paths.analysisPath.view} element={<ObjectCardCompetition/>}/>
        <Route path={paths.compilationPath.list} element={<Collection/>}/>
        <Route path={paths.compilationPath.view} element={<ObjectCardCollection/>}/>
        <Route path={paths.viewPath} element={<PublicView/>}/>
        <Route path={paths.confirmShowPath} element={<ConfirmShow/>}/>
        <Route path={paths.confirmViewPath} element={<ConfirmView/>}/>
        <Route path={paths.confirmPhonePath} element={<ConfirmPhone/>}/>
        <Route path={paths.confirmPersonShowPath} element={<ConfirmPersonShow/>}/>
        <Route path={paths.confirmPersonViewPath} element={<ConfirmPersonView/>}/>
        <Route path={paths.confirmPersonAgreementPath} element={<ConfirmPersonAgreement/>}/>
        <Route path={paths.agreementPath} element={<ConfirmAgreement/>}/>
      </Routes>
    </BrowserRouter>
  )
}