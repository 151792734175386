import { Client } from "../index";
import { ConfirmationRequest, ConfirmationResponse, Token } from "../../types";

export const SignService = {

  sendShowSignCode: (showId: string) => Client.post<Token>(`/shows/${showId}/sendSignCode`),

  signShow: (showId: string, payload: ConfirmationRequest) =>
    Client.post<ConfirmationResponse>(`/shows/${showId}/sign`, payload),

  sendViewSignCode: (viewId: string) => Client.post<Token>(`/views/${viewId}/sendSignCode`),

  signView: (viewId: string, payload: ConfirmationRequest) =>
    Client.post<ConfirmationResponse>(`/views/${viewId}/sign`, payload),

  sendAgreementSignCode: (applicationId: string) => Client.post<Token>(`/agreement/${applicationId}/sendSignCode`),

  signAgreement: (applicationId: string, payload: ConfirmationRequest) =>
    Client.post<ConfirmationResponse>(`/agreement/${applicationId}/sign`, payload),

  getDocument: (url: string) => Client.get(`${url}`),

}