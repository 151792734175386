import { Client } from "../index";
import { BuyApplication, Object, ObjectList, Vote } from "../../types";

export const BuyService = {

  getBuyApplication: (applicationId: string) => Client.get<BuyApplication>(`/applications/buy/${applicationId}`),

  getCollection: (applicationId: string) => Client.get<ObjectList>(`/applications/buy/${applicationId}/collection`),

  getCollectionObject: (applicationId: string, objectId: string) =>
    Client.get<Object>(`/applications/buy/${applicationId}/collection/${objectId}`),

  voteToObject: (applicationId: string, objectId: string, vote: Vote) =>
    Client.post(`/applications/buy/${applicationId}/collection/${objectId}`, {voteStatus: vote}),
}