import { ReactComponent as Like } from "../../../style/assets/icon-like-green.svg";
import { ReactComponent as Dislike } from "../../../style/assets/icon-dislike-red.svg";
import { ObjectPreviewCard } from "../../../types";
import { memo } from "react";
import { formatThousand } from "../../../service";

type Props = {
  object: ObjectPreviewCard
  onClick: (id: string) => void
}

export const ObjectPreview = memo((
  {
    object,
    onClick
  }: Props) => {

  return (
    <div className={"object-card"} onClick={() => onClick(object.id)}>
      <img
        src={object.photo}
        className={"position-relative"}
        alt={""}
        style={{height: "180px", width: "343px"}}
      />
      <div className={"object-card-preview-vote"}>
        {object.type === "collection" &&
          (object.voteStatus === "like" ? <Like/> : <Dislike/>)
        }
        {object.type === "competition" && <img src={object.adLogo} alt={""}/>}
      </div>
      <div className={"d-flex flex-column g-2 p-3"}>
        <div className={"d-flex flex-row subtitle"}>
          <div>
            {object.title}
          </div>
          <div>
            {`${formatThousand(object.price)} ₽`}
          </div>
        </div>
        <div className={"text mt-2"}>
          {object.address}
        </div>
      </div>
    </div>
  )
})