import { CollectionPreview, Competitors } from "../../../types";
import { ReactComponent as Arrow } from "../../../style/assets/icon-arrow-right.svg";
import { useNavigate } from "react-router";
import { memo } from "react";

type Props = {
  type: "collection" | "competition" | "base"
  competitors?: Competitors
  collection?: CollectionPreview
  applicationId?: string
  token?: string
  id?: string
}

export const Compilation = memo((
  {
    type,
    collection,
    competitors,
    applicationId,
    token,
    id
  }: Props) => {

  const navigate = useNavigate()

  const openCollection = () => {
    navigate(`/compilation?applicationId=${applicationId}&token=${token}`)
  }

  const openCompetition = () => {
    navigate(`/competition?applicationId=${applicationId}&token=${token}`)
  }

  const openConfirmPhone = () => {
    navigate(`/confirm-phone?id=${id}`)
  }

  const onClick = () => {
    switch (type) {
      case "collection":
        return openCollection()
      case "competition":
        return openCompetition()
      case "base":
        return openConfirmPhone()
      default:
        return () => {
        }
    }
  }

  return (
    <div className={"wrapper-gradient"}>
      {type === "base" &&
        <div className={"d-flex flex-column gap-2"}>
          <div className={"enter-phone-text"}>
            Не рискуйте
          </div>
          <div className={"text-large"}>
            Вам нужен риэлтор
          </div>
          <div></div>
        </div>
      }
      {type === "collection" && collection &&
        <div className={"d-flex flex-column gap-2"}>
          <div className={"enter-phone-text"}>
            Подобрано
          </div>
          <div className={"text-large"}>
            {`${collection.selectedPropertiesCount} объектов`}
          </div>
          <div></div>
        </div>
      }
      {type === "competition" && competitors &&
        <div className={"d-flex flex-column gap-2"}>
          <div className={"enter-phone-text"}>
            {competitors.count ? "Найдено" : "Анализ конкурентов"}
          </div>
          <div className={"text-large"}>
            {`${competitors.count ? `${competitors.count} конкурентов` : "в процессе..."}`}
          </div>
          <div/>
        </div>
      }
      <button className={"btn btn-secondary d-flex align-items-center justify-content-center mt-4"}
              onClick={onClick}>
        {type === "base" && "Бесплатная консультация"}
        {type !== "base" &&
          <div className={"d-flex align-items-center justify-content-center gap-2"}>
            Смотреть
            <Arrow/>
          </div>
        }
      </button>
    </div>
  )
})