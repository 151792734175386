import { AgentPreview } from "../../../types";
import { ReactComponent as Phone } from "../../../style/assets/icon-phone.svg";
import { ReactComponent as TG } from "../../../style/assets/icon-telegram.svg";
import { memo } from "react";

type Props = {
  agent: AgentPreview
}

export const Agent = memo((
  {
    agent
  }: Props) => {
  console.log(agent)
  return (
    <div className={"wrapper mt-40"}>
      <div className={"d-flex flex-column"}>
        <div className={"d-flex flex-row"}>
          <img src={agent.avatarUrl} alt={"..."} className={"avatar"}/>
          <div className={"d-flex flex-column ms-2 mt-1"}>
            <div className={"text"}>
              {agent.positionName}
            </div>
            <div className={"label-white"}>
              {agent.name}
            </div>
          </div>
        </div>
        <a href={`tel:${agent.contact.phone.replaceAll(" ", "").replaceAll("(", "").replaceAll(")" ,"")}`}
           className={"btn btn-secondary mt-4 d-flex justify-content-center align-items-center"}>
          <div>
            Позвонить
          </div>
          <Phone/>
        </a>
        <a href={`${agent.contact.telegram}`}
           className={"btn btn-primary mt-2 d-flex justify-content-center align-items-center gap-2"}>
          <div>
            Написать в Телеграм
          </div>
          <TG/>
        </a>
      </div>
    </div>
  )
})