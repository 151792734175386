import { Show } from "../../../types";
import dayjs from "dayjs";
import { useQueryParams } from "../../../service";
import { useNavigate } from "react-router";

type Props = {
  show: Show
}

const days = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота'
];

export const ViewCard = (
  {
    show
  }: Props) => {

  const {queryParams} = useQueryParams<{ applicationId: string, token: string }>([
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"}
  ])

  const navigate = useNavigate()

  const finishView = () => {
    navigate(`/confirm-view?viewId=${show.id}&applicationId=${queryParams.applicationId}
    &token=${queryParams.token}&document=${show.documentUrl}`)
  }

  const openObject = () => {
    navigate(`/compilation/object?applicationId=${queryParams.applicationId}
    &token=${queryParams.token}&objectId=${show.collectionObjectId}&from=application`)
  }

  return (
    <div className={"wrapper d-flex flex-column"}>
      <div className={"label mb-2"}>
        {show.isFinished ? "Просмотр состоялся" : "Просмотр назначен на"}
      </div>
      <div className={"date"}>
        {`${dayjs(show.date).format("DD.MM")} 
        ${days[ new Date(show.date).getDay() ]}, ${dayjs(show.date).format("MM:ss")}`}
      </div>
      {!show.isFinished &&
        <button
          className={"btn btn-outline-dark d-flex justify-content-center align-items-center mt-3"}
          onClick={finishView}
        >
          Завершить просмотр
        </button>
      }
      {!show.isFinished &&
        <button
          className={"btn btn-primary d-flex justify-content-center align-items-center mt-3"}
          onClick={openObject}
        >
          Открыть объект
        </button>
      }
    </div>
  )
}