import { paths } from "../../router";
import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { useNavigate } from "react-router";
import { useQueryParams } from "../../../service";
import { useState } from "react";

export const ConfirmAgreement = () => {

  const {queryParams} = useQueryParams<{
    applicationId: string,
    token: string,
    document: string,
    type: "sale" | "buy",
    status: 'signed' | 'wait' | "oral" | "offline"
  }>([
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"},
    {name: "document", type: "string"},
    {name: "type", type: "string"},
    {name: "status", type: "string"},
  ])

  const navigate = useNavigate()

  const closeConfirm = () => {
    if (queryParams.type === "sale") {
      navigate(`${paths.sellAppPath}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
    } else {
      navigate(`${paths.buyAppPath}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
    }
  }

  const onApply = () => {
    navigate(`${paths.confirmPersonAgreementPath}?applicationId=${queryParams.applicationId}
    &token=${queryParams.token}`)
  }

  const [checked, setChecked] = useState(false)

  return (
    <div className={"d-flex flex-column justify-content-between h-100 align-items-center"}>
      <div className={" d-flex flex-column justify-content-between overflow-auto w-100 h-100"}>
        <div className={"subtitle w-100 mb-5"}>
          Договор
          <Close onClick={closeConfirm} className={"cursor-pointer"}/>
        </div>
        <div className={"overflow-hidden text-white h-100"}>
          {queryParams.document &&
            <iframe
              id="myIframe"
              src={queryParams.document}
              className={"w-100 h-100 iframe-document"}
            />
          }
        </div>
      </div>
      <div className={"w-100"}>
        <div className={"line mb-2"}>
        </div>
        {queryParams.status === "wait" &&
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onClick={() => setChecked(!checked)}
            />
            <label className="form-check-label text-white mt-2" htmlFor="flexCheckDefault">
              Полностью ознакомился /-ась и прочитал /-а
            </label>
          </div>
        }
        {queryParams.status === "wait" &&
          <button
            className={"btn btn-primary"}
            disabled={queryParams.status === "wait" && !checked}
            onClick={onApply}>
            {queryParams.status === "wait" ? "Согласен" : "Расторгнуть"}
          </button>
        }
      </div>
    </div>
  )
}