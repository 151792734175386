import { paths } from "../../router";
import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { useNavigate } from "react-router";
import { useQueryParams } from "../../../service";
import { useState } from "react";

export const ConfirmView = () => {

  const {queryParams} = useQueryParams<{
    viewId: string,
    applicationId: string,
    token: string,
    document: string
  }>([
    {name: "viewId", type: "string"},
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"},
    {name: "document", type: "string"},
  ])

  const navigate = useNavigate()

  const closeConfirm = () => {
    navigate(`${paths.buyAppPath}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
  }

  const onApply = () => {
    navigate(`${paths.confirmPersonViewPath}?applicationId=${queryParams.applicationId}
    &token=${queryParams.token}&viewId=${queryParams.viewId}`)
  }

  const [checked, setChecked] = useState(false)

  return (
    <div className={"d-flex flex-column justify-content-between h-100 align-items-center"}>
      <div className={" d-flex flex-column justify-content-between overflow-auto w-100 h-100"}>
        <div className={"subtitle w-100 mb-5"}>
          Подтверждение просмотра
          <Close onClick={closeConfirm} className={"cursor-pointer"}/>
        </div>
        <div className={"overflow-hidden text-white h-100"}>
          {queryParams.document &&
            <iframe
              id="myIframe"
              src={queryParams.document}
              className={"w-100 h-100 iframe-document"}
            />
          }
        </div>
      </div>
      <div className={" w-100"}>
        <div className={"line"}>
        </div>
        <div className="form-check mb-3 mt-2">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            onClick={() => setChecked(!checked)}
          />
          <label className="form-check-label text-white mt-2" htmlFor="flexCheckDefault">
            Полностью ознакомился /-ась и прочитал /-а
          </label>
        </div>
        <button
          className={"btn btn-primary"}
          disabled={!checked}
          onClick={onApply}
        >
          Согласен
        </button>
      </div>
    </div>
  )
}