import { formatThousand, useLoadSaleApplication, useQueryParams } from "../../../service";
import { CarouselComponent } from "../carousel";
import { Agent } from "../agent";
import { ShowCard } from "../show-card";
import { Compilation } from "../compilation";
import { AdCampany } from "../ad-campany";
import { AgreementComponent } from "../agreement";
import { Progress } from "../progress";
import { ReactComponent as Logo } from "../../../style/assets/Logo.svg";

export const SaleApplication = () => {

  const {queryParams} = useQueryParams<{ applicationId: string, token: string }>([
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"}
  ])

  localStorage.setItem("sdelka-public-token", queryParams.token)

  const {application} = useLoadSaleApplication({applicationId: queryParams.applicationId})

  return (
    <>
      {application &&
        <div>
          <CarouselComponent object={application.objectGalleryCard}/>
          <div className={"d-flex flex-column mt-3"}>
            <div className={"d-flex flex-row address-label justify-content-between"}>
              <div className={""}>
                {application.objectGalleryCard.title}
              </div>
              <div>
                {`${formatThousand(application.objectGalleryCard.price)} ₽`}
              </div>
            </div>
            <div className={"text mt-2"}>
              {application.objectGalleryCard.address}
            </div>
          </div>
          <Agent agent={application.agent}/>
          {application.plannedShows &&
            <div className={"mt-40 title"}>
            ПРЕДСТОЯЩИЕ ПОКАЗЫ
          </div>
          }
          <div
            className={"d-flex flex-row gap-2 mt-2 overflow-x-auto overflow-no-scroll"}
            style={{width: "359px"}}
          >
            {application.plannedShows?.map(el => (
              <ShowCard show={el} key={el.id}/>
            ))}
          </div>
          {application.finishedShows &&
            <div className={"mt-40 title"}>
            ПРОШЕДШИЕ ПОКАЗЫ
          </div>
          }
          <div
            className={"d-flex flex-row gap-2 mt-2 overflow-x-auto  overflow-no-scroll"}
            style={{width: "359px"}}
          >
            {application.finishedShows?.map(el => (
              <ShowCard show={el} key={el.id}/>
            ))}
          </div>
          <Compilation
            type={"competition"}
            competitors={application.competitors}
            applicationId={queryParams.applicationId}
            token={queryParams.token}
          />
          <div className={"mt-40 title mb-2"}>
            РЕКЛАМНАЯ КАМПАНИЯ
          </div>
          <AdCampany
            adCampaign={application.adCampaign}
            otherAdCampaign={application.otherAdCampaign}
          />
          {application.agreement &&
            <AgreementComponent
              agreement={application.agreement}
              applicationId={application.id}
              type={"sale"}
              token={queryParams.token}
            />
          }
          <div className={"mt-40 title mb-2"}>
            ВЫПОЛНЕНИЕ ДОГОВОРА
          </div>
          <Progress progress={application.progress}/>
          <div className={"line mt-40"}/>
          <Logo className={"footerLogo"}/>
        </div>
      }
    </>
  )
}