import { useLoadCollection, useQueryParams } from "../../../service";
import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { paths } from "../../router";
import { useNavigate } from "react-router";
import { ObjectPreview } from "../object-preview";
import { memo } from "react";

export const Collection = memo(() => {

  const {queryParams} = useQueryParams<{ applicationId: string, token: string }>([
    {name: "applicationId", type: "string"},
    {name: "token", type: "string"}
  ])

  const navigate = useNavigate()

  const closeConfirm = () => {
    navigate(`${paths.buyAppPath}?applicationId=${queryParams.applicationId}&token=${queryParams.token}`)
  }

  const openObject = (id: string) => {
    navigate(`${paths.compilationPath.view}?applicationId=${queryParams.applicationId}
    &objectId=${id}&token=${queryParams.token}`)
  }

  const {collection} = useLoadCollection({applicationId: queryParams.applicationId})

  return (
    <>
      {collection &&
        <div className={"d-flex flex-column"}>
          <div className={"subtitle"} style={{width: "346px"}}>
            Подборка
            <Close onClick={closeConfirm}
                   className={"cursor-pointer"}
            />
          </div>
          <div className={"d-flex flex-column gap-3 mt-40"}>
            {collection.data.map(el => (
              <ObjectPreview
                key={el.id}
                object={el}
                onClick={openObject}
              />
            ))}
          </div>
        </div>
      }
    </>
  )
})