import { ProgressStep } from "../../../types/ProgressStep";
import { ReactComponent as Check } from "../../../style/assets/icon-check.svg";
import { memo } from "react";

type Props = {
  progress: ProgressStep[]
}

export const Progress = memo((
  {
    progress
  }: Props) => {

  return (
    <div className={"wrapper mt-2 gap-3 d-flex flex-column"}>
      {progress.map(el => (
        <div className={"d-flex align-items-center justify-content-between"} key={el.step}>
          <div
            className={`${el.status === "completed" && "text-decoration-line-through"} progressStep enter-phone-text `}>
            {el.step}
          </div>
          {el.status === "completed" ? <Check/> : <div className={"text"}>В процессе</div>}
        </div>
      ))}
    </div>
  )
})