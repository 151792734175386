export function formatThousand(num: number) {
  let numStr = num.toString()

  let parts = []
  for (let i = numStr.length; i > 0; i -= 3) {
    let start = i - 3 < 0 ? 0 : i - 3
    parts.unshift(numStr.slice(start, i))
  }

  return parts.join(" ");
}

