import { AdCampaign } from "../../../types";
import { ReactComponent as Link } from "../../../style/assets/icon-link.svg";
import { memo } from "react";

type Props = {
  ad: AdCampaign
}

export const AdCard = memo((
  {
    ad
  }: Props) => {

  const openLink = () => {
    window.open(`${ad.adUrl}`)
  }

  return (
    <div className={"wrapper d-flex align-items-center"}
         style={{height: "56px"}}>
      <div className={"text-white w-100 d-flex justify-content-center align-items-center"}
           style={{height: "56px"}}>
        {ad.logo &&
          <img src={ad.logo} className={"adLogo"} alt={""}/>
        }
        {ad.name && !ad.logo && ad.name}
      </div>
      <div className={"d-flex justify-content-end"}
           onClick={openLink}>
        <Link className={"cursor-pointer"}/>
      </div>
    </div>
  )
})