import { api } from "../../transport";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka-public/load-sale-application/DEFAULT_QN"

type Props = {
  applicationId: string
}

export const useLoadSaleApplication = (
  {
    applicationId
  }: Props) => {

  const loadSaleApplication = () => api.sale.getSaleApplication(applicationId)

  const {data, isLoading, refetch, remove, error, isFetching, isFetched} = useQuery(
    DEFAULT_QN,
    loadSaleApplication,
    {
      keepPreviousData: true
    }
  );

  return {
    application: data?.data,
    isLoading,
    isFetching,
    isFetched,
    refetch,
    remove,
    error
  }
}