import { Client } from "../index";
import { Object, ObjectList, SaleApplication } from "../../types";

export const SaleService = {

  getSaleApplication: (applicationId: string) => Client.get<SaleApplication>(`/applications/sale/${applicationId}`),

  getConcurrency: (applicationId: string) => Client.get<ObjectList>(`/applications/sale/${applicationId}/concurrency`),

  getConcurrencyObject: (applicationId: string, objectId: string) =>
    Client.get<Object>(`/applications/sale/${applicationId}/concurrency/${objectId}`),

}