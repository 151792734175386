import { paths } from "../../router";
import { ReactComponent as Close } from "../../../style/assets/icon-close.svg";
import { useNavigate } from "react-router";
import { parsePhone, useCallMe, useQueryParams } from "../../../service";
import { useState } from "react";

export const ConfirmPhone = () => {

  const {queryParams} = useQueryParams<{ id: string }>([
    {name: "id", type: "string"},
  ])

  const [phone, setPhone] = useState<string | undefined>(undefined)

  const [called, setCalled] = useState<boolean>(false)

  const navigate = useNavigate()

  const closeConfirm = () => {
    navigate(`${paths.viewPath}?id=${queryParams.id}`)
  }

  const {callMe} = useCallMe()

  const onSubmitCall = () => {
    callMe({
      id: queryParams.id,
      phone: (phone || "")
        .replaceAll("+", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll(" ", "")
        .replaceAll("-", "")
        .slice(0, 11)
    })
    setCalled(true)

    setTimeout(() => {
      closeConfirm()
    }, 5000)
  }


  return (
    <div className={"d-flex flex-column justify-content-between h-100 align-items-center"}>
      <div className={"h-50 d-flex flex-column justify-content-between w-100"}>
        <div className={"d-flex justify-content-end"}>
          <Close onClick={closeConfirm} className={"cursor-pointer"}/>
        </div>
        {called &&
          <div>
            <div className={"enter-phone w-100 d-flex justify-content-center"}>
              Заявка отправлена.
            </div>
          </div>
        }
        {!called &&
          <div>
            <div className={"enter-phone"}>
              Введите номер телефона
            </div>
            <div className={"enter-phone-text"}>
              Мы с Вами свяжемся
            </div>
            <input
              className="form-control input-text mb-5 mt-3"
              id="confirm-person"
              value={parsePhone(phone || "")}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        }
      </div>
      {!called &&
        <button className={"btn btn-primary"} onClick={onSubmitCall}>
          Подтвердить
        </button>
      }
    </div>
  )
}