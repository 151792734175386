import { api } from "../../transport";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka-public/load-collection/DEFAULT_QN"

type Props = {
  applicationId: string
}

export const useLoadCollection = (
  {
    applicationId
  }: Props) => {

  const loadCollection = () => api.buy.getCollection(applicationId)

  const {data, isLoading, refetch, remove, error, isFetching, isFetched} = useQuery(
    DEFAULT_QN,
    loadCollection,
    {
      keepPreviousData: true
    }
  );

  return {
    collection: data?.data,
    isLoading,
    isFetching,
    isFetched,
    refetch,
    remove,
    error
  }
}