import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Client } from "./transport";
import { QueryClient, QueryClientProvider } from "react-query";
import { ClientContext } from './transport/ClientContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ClientContext.Provider value={Client}>
      <QueryClientProvider client={queryClient}>
        <App/>
      </QueryClientProvider>
    </ClientContext.Provider>
  </React.StrictMode>
);

