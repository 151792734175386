import { useMutation } from "react-query";
import { Token } from "../../types";
import { AxiosResponse } from "axios";
import { api } from "../../transport";

const DEFAULT_MN = "sdelka-public/send-agreement-sign-code/DEFAULT_MN";

type Props = {
  onSuccess?: (data: AxiosResponse<Token>) => void
}

export const useSendAgreementSignCode = (
  {
    onSuccess
  }: Props) => {

  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({applicationId}: { applicationId: string }) =>
      api.sign.sendAgreementSignCode(applicationId),
    onSuccess,
  });

  return {
    send: mutation.mutate,
    isLoading: mutation.isLoading
  }
}